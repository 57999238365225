import React from "react";
import { Helmet } from "react-helmet";
import { SiteData } from "../../consts/SiteData";
import { Container, Icon, Image } from "semantic-ui-react";
import { MainContents } from "../../components/MainContents";
import PageLayout from "../../components/PageLayout";

const ShareNinjaPage = () => {
  return (
    <PageLayout>
      <Helmet>
        <html lang="ja" />
        <title>{SiteData.PAGES.SHARE_NINJA.TITLE}</title>
      </Helmet>
      <MainContents
        pageTitle={
          <>
            <span>
              モバイル <Icon name="arrows alternate horizontal" />
              PC 間ファイル共有サービス
            </span>
            <br />
            <a href="https://share2.ninja" target="_blank" rel="noreferrer">
              「 Share Ninja 」
            </a>
          </>
        }
      >
        <Container
          as="a"
          href="https://share2.ninja"
          target="_blank"
          rel="noreferrer"
        >
          <Image
            src={"/assets/shareninjaimage_1.png"}
            style={{ opacity: 0.8 }}
            fluid
            centered
          />
          <Image
            src={"/assets/shareninjaimage_2.png"}
            style={{ opacity: 0.8 }}
            fluid
            centered
          />
        </Container>
      </MainContents>
    </PageLayout>
  );
};

export default ShareNinjaPage;
